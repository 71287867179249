

export default {
  name: 'MoleculeFiltersPins3D',
  filters: {
    replace: function (st, rep, repWith) {
      return st.split(rep).join(repWith)
    }
  },
  computed: {
    defaultMode() {
      return this.$store.state.building.defaultFilterPinsMode || ''
    },
    buildingStore() {
      return this.$store.state.building
    },
    buildings() {
      return this.$store.state.project.project.buildings || []
    },
    floors() {
      return this.$store.state.project.project.floors || []
    },
    pinCategories() {
      return this.$store.getters?.getActiveProjectSettings?.building?.pinsData?.categories || []
    },
    allPinsData() {
      return this.$store.getters?.getActiveProjectSettings?.building?.pinsData?.pins || []
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    selectedBuilding() {
      return this.$store.state.availability?.filters?.buildingId
    },
    buildingData() {
      return this.buildings.find((b) => b.id === this.selectedBuilding) || { code: 'main_view' }
    },
    pinData() {
      return this.allPinsData.find((p) => p.building_code === this.buildingData.code) || { building_code: '' }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
    this.$nextTick(() => {
      this.resizeListener()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    filterPins3D(category) {
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.showPinsByCategory(category)
      }
      this.$store.dispatch('building/setDefaultFilterPinsMode', category)
    },
    hideCategory(category) {
      const matchingPin = this.allPinsData.find(pin =>
        pin.category === category.id && pin.building_code === this.buildingData.code
      );
      return !matchingPin;
    },
    getPinCategoryData(id) {
      return this.pinCategories.find((c) => c.id === id)
    },
    resizeListener(e) {
      let activeCategoryPin
      this.pinCategories.forEach((category) => {
        if (category.activeCategory) {
          activeCategoryPin = category
        }
      })
      if (window.innerWidth < 1200) {
        this.filterPins3D('none')
      } else {
        if (activeCategoryPin) {
          this.filterPins3D(activeCategoryPin.id)
        } else {
          this.filterPins3D('')
        }
      }
    }
  }
}
