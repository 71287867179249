
export default {
  name: 'MoleculeCustomers',
  props: {
    customers: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    titleCustomers: {
      required: false,
      type: String,
      default: ''
    }
  }
}
